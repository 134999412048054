import React from 'react';

const SignUp1 = React.lazy(() => import('./views/Authentication/SignUp/SignUp1'));
const Signin1 = React.lazy(() => import('./views/Authentication/SignIn/SignIn1'));

const route = [
    { path: '/cadastro', exact: true, name: 'Cadastro', component: SignUp1 },
    { path: '/login', exact: true, name: 'Login', component: Signin1 }
]; 

export default route; 